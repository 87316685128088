import axios from "axios";
import router from "@/router";
import { useToast } from "vue-toastification";

const toast = useToast();

const baseUrl = process.env.VUE_APP_ROOT_API;

const state = {
  modalState: false,
  showAuthModal: false,
  error: "",
  responseMessage: "",
  token: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
  cardName: [],
  subCategories: [],
  blogs: [],
  blog: "",
  articles: [],
  tags: ["Article", "News"],
};

const getters = {
  modalState: (state) => state.modalState,
  showAuthModal: (state) => state.showAuthModal,
  errorMssg: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isSuccess: (state) => state.isSuccess,
  isError: (state) => state.isError,
  cardName: (state) => state.cardName,
  subCategories: (state) => state.subCategories,
  blogs: (state) => state.blogs,
  blog: (state) => state.blog,
  tags: (state) => state.tags,
  articles: (state) => state.articles,
  token: (state) => state.token,
};

const actions = {
  async signIn({ commit, state }, { email, password }) {
    state.isLoading = true;
    try {
      const response = await axios.post(`${baseUrl}/auth/login`, {
        email,
        password,
      });
      commit("reRoute", response);
    } catch (error) {
      if (error.response) {
        state.isLoading = false;
        toast.error("Oops!, this account does not exist.", {
          timeout: 5000,
        });
        window.scrollTo(0, 0);
      }
    }
  },
  async deleteAccount({ commit, state }, { reason, token }) {
    state.isLoading = true;
    try {
      await axios.delete(`${baseUrl}/users`, {
        data: { reason },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      state.isLoading = false;
      toast.success("Account Successfully Deleted 🎉");
      router.push("/");
    } catch (error) {
      state.isLoading = false;
      if (error.response) {
        const errorMssg = error.response.data.msg;
        window.scrollTo(0, 0);
        commit("showError", errorMssg);
      }
    }
  },
  async registerUser(
    { commit, state },
    {
      firstName,
      lastName,
      userName,
      email,
      phoneNumber,
      countryCode,
      referedBy,
      type,
      password,
    }
  ) {
    state.isLoading = true;
    try {
      const response = await axios.post(`${baseUrl}/auth/register`, {
        firstName,
        lastName,
        userName,
        email,
        phoneNumber,
        countryCode,
        referedBy,
        type,
        password,
      });
      state.responseMessage = response;
      commit("toggleAuthModal");
      state.isLoading = false;
    } catch (error) {
      if (error.response) {
        state.isLoading = false;
        const errorMssg = error.response.data.msg;
        window.scrollTo(0, 0);
        commit("showError", errorMssg);
      }
    }
  },
  async contactUs({ commit, state }, { from, email, category, subject, body }) {
    state.isLoading = true;
    try {
      const response = await axios.post(
        `${baseUrl}/notification/contactusmessage`,
        {
          from,
          email,
          category,
          subject,
          body,
        }
      );
      state.responseMessage = response;
      commit("toggleModal");
      state.isLoading = false;
    } catch (error) {
      if (error.response) {
        state.isLoading = false;
        state.responseMessage = error;
      }
    }
  },
  async resendMail({ state, commit }, { email }) {
    state.isLoading = true;
    try {
      const response = await axios.post(`${baseUrl}/auth/resendlink`, {
        email,
      });
      state.responseMessage = response;
      commit("resendMail");
      state.isLoading = false;
    } catch (error) {
      if (error.response) {
        state.isLoading = false;
        state.responseMessage = error;
      }
    }
  },
  async verifyAccount({ commit, state }, user_details) {
    state.isLoading = true;
    try {
      await axios.put(
        `${baseUrl}/auth/verify/${user_details.email}/${user_details.token}`
      );
      commit("resendMail");
      state.isLoading = false;
      state.isSuccess = true;
    } catch (error) {
      state.isLoading = false;
      state.isError = true;
      if (
        error.response.data.msg ===
        "User has been already verified. Please Login"
      ) {
        state.error =
          "Your account has already been verified. Please go to the app and login";
      } else if (
        error.response.data.msg ===
        "We were unable to find a user for this verification. Please SignUp!"
      ) {
        state.error =
          "This email is not associated with any account. Please Sign Up!";
      }
    }
  },
  getCardName({ commit }) {
    axios.get(`${baseUrl}/categories?parentCategory=1`).then((response) => {
      commit("getCardName", response.data.data);
    });
  },
  getCardNameSubCategories({ commit }, id) {
    axios.get(`${baseUrl}/categories?parentCategory=${id}`).then((response) => {
      commit("getCardNameSubCategories", response.data.data);
    });
  },
  getBlogs({ state, commit }) {
    state.isLoading = true;
    axios.get(`${baseUrl}/blogs`).then((response) => {
      commit("getBlogs", response.data.data);
      state.isLoading = false;
    });
  },
  getSingleBlog({ commit }, id) {
    state.isLoading = true;
    axios.get(`${baseUrl}/blogs?id=${id}`).then((response) => {
      commit("getSingleBlog", response.data.data);
      state.isLoading = false;
    });
  },
  getArticles({ commit }) {
    axios.get(`${baseUrl}/blogs`).then((response) => {
      const sliceRange = response.data.data.slice(0, 2);
      commit("getArticles", sliceRange);
    });
  },
};

const mutations = {
  toggleModal(state) {
    state.modalState = !state.modalState;
  },
  toggleAuthModal(state) {
    state.showAuthModal = !state.showAuthModal;
  },
  resetModal(state) {
    state.modalState = false;
  },
  showError(state, payload) {
    state.error = payload;
    setTimeout(() => {
      state.error = "";
    }, 3000);
  },
  resendMail(state) {
    state;
  },
  getCardName(state, payload) {
    state.cardName = payload;
  },
  getCardNameSubCategories(state, payload) {
    state.subCategories = payload;
  },
  getBlogs(state, payload) {
    state.blogs = payload;
  },
  getSingleBlog(state, payload) {
    state.blog = payload;
  },
  getArticles(state, payload) {
    state.articles = payload;
  },
  reRoute(state, response) {
    state.token = response.data.data.token;
    router.push({
      path: "/delete-account-reason",
      query: { token: state.token },
    });
    state.isLoading = false;
  },
};

export default {
  getters,
  mutations,
  actions,
  state,
};
